import React, { useState, useEffect, useContext, PropsWithChildren } from 'react';
import { BeatLoader } from 'react-spinners';
import { Box, Theme, SxProps } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { AuthContext } from './AuthContext';
import { Types } from '../constants';
import { GatewayManagementAPI, BlogPostsAPI, Cookie, CommonLinksAPI } from '../services';
import { areasOfPlanningTitles } from '../components/utility-funcs/planningAreasTitles';
import { navigateToRoute } from '../utils/navigateLandingRoute';
import { IDocumentLibraryType, LinkCategories, MessageTypes } from '../constants/Types';
import * as Utils from '../utils/index';
import { LinkCategoriesTypeEnum } from '../constants/CommonLinksConstants';

let theme = createTheme();

const userContextStyles: Record<string, SxProps<Theme> | undefined> = {
  container: {
    backgroundColor: '#2c1b68',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center !important',
    flexDirection: 'column',
    transition: 'opacity 0.3s linear',
    zIndex: 99999999,

    '.loaderText': {
      fontFamily: 'Neuzeit-Grotesk, sans-serif',
      fontWeight: 'bold',
      fontSize: '35px',
      letterSpacing: 0,
      color: '#fff',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px',
      },
    },
  },
};

interface IFullScreenLoader {
  customText?: string;
}

export const FullScreenLoader = ({ customText }: IFullScreenLoader) => {
  const authContext = useContext(AuthContext);

  return (
    <Box sx={userContextStyles.container} style={{ backgroundColor: authContext.customBgColor.color }}>
      <div className="loaderText loading-spinner-text">
        {customText ? customText : 'Just a moment while we prepare your content...'}
      </div>
      <div className="loading-container">
        <BeatLoader color="#919BD1" size={20} margin={2} />
      </div>
    </Box>
  );
};

export const UserContext = React.createContext<Types.IUserContext>({
  syncNewUsersLoading: false,
  syncUsersInfoLoading: false,
  shadowedRole: {
    isShadowed: false,
    shadowedRole: {
      label: '',
      id: '',
    },
    assignedRole: {
      label: '',
      id: '',
    },
  },
  shadowedUser: {
    isShadowed: false,
    shadowedUserEmail: '',
  },
  documentLibrary: IDocumentLibraryType.Sharefile,
  isLoading: false,
  user: {},
  role: {},
  apps: {},
  posts: {},
  advisors: {},
  requestTemplates: [],
  keysFinancialPlanning: [],
  recentTransactions: [],
  portfolioServiceName: '',
  portfolioData: {},
  baseUrl: '',
  toastifySettings: { type: '', msg: '' },
  defaultOktaSessionExpirationHours: 2,
  maxGatewaySessionExpirationHours: { success: false, value: 2 },
  linkCategories: {},
  setLinkCategories: () => null,
  setToastifySettings: () => null,
  setSyncNewUsersLoading: () => null,
  setSyncUsersInfoLoading: () => null,
  refresh: () => null,
  refreshRole: async () => undefined,
  refreshUser: async () => undefined,
  refreshPosts: async () => undefined,
  onUserSetUp: () => null,
  setPosts: () => null,
  activateShadowedRole: () => null,
  activateShadowedUser: () => null,
  setRecentTransactions: () => null,
  setPortfolioServiceName: () => null,
  setPortfolioData: () => null,
  extendGatewaySession: () => null,
  extendOktaSession: async () => ({
    success: false,
  }),
  sortedRows: [],
  setSortedRows: () => null,
  reliFilter: '',
  setReliFilter: () => null,
  reliSortOptions: { sortBy: [] },
  setReliSortOptions: () => null,
  reliPagination: null,
  setReliPagination: () => null,
  setRole: () => null,
});

const defaultOktaSessionExpirationHours = 2;
const HOUR_MILISEC = 60 * 60 * 1000;
const empty_toast_settings = { type: '', msg: '' };

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [shadowedRole, setShadowedRole] = useState<Types.IShadowedRole>({
    isShadowed: false,
    shadowedRole: {
      label: '',
      id: '',
    },
    assignedRole: {
      label: '',
      id: '',
      isAdmin: false,
    },
  });

  const [shadowedUser, setShadowedUser] = useState<Types.IShadowedUser>({
    isShadowed: false,
    shadowedUserEmail: '',
  });

  const [user, setUser] = useState<Partial<Types.User>>({ success: false });
  const [role, setRole] = useState<Partial<Types.RoleType>>({ success: false });
  const [apps, setApps] = useState<Partial<Types.Apps>>({ success: false });
  const [posts, setPosts] = useState<Partial<Types.Posts>>({ success: false, posts: [] });
  const [requestTemplates, setRequestTemplates] = useState<Types.RequestTemplate[]>([]);
  const [linkCategories, setLinkCategories] = useState<Partial<LinkCategories>>({ success: false });
  const [advisors, setAdvisors] = useState<Partial<Types.Advisors>>({ success: false });
  const [keysFinancialPlanning, setKeysFinancialPlanning] = useState<Types.IKeyAreasOfPlanning[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [syncNewUsersLoading, setSyncNewUsersLoading] = useState(false);
  const [syncUsersInfoLoading, setSyncUsersInfoLoading] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState<Types.ITransaction[]>([]);
  const [portfolioServiceName, setPortfolioServiceName] = useState<string>('');
  const [portfolioData, setPortfolioData] = useState<Partial<Types.IPortfolioAccounting>>({});
  const [toastifySettings, setToastifySettings] = useState(empty_toast_settings);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [maxGatewaySessionExpirationHours, setMaxGatewaySessionExpirationHours] = useState({
    success: false,
    value: defaultOktaSessionExpirationHours,
  });
  const [documentLibrary, setDocumentLibrary] = useState<Types.IDocumentLibraryType>(IDocumentLibraryType.Sharefile);
  // remove everything related to relifiles after reli BE pagination will be implemented
  const [sortedRows, setSortedRows] = useState<
    {
      id: string;
      state: string;
    }[]
  >([]);
  const [reliFilter, setReliFilter] = useState('');
  const [reliSortOptions, setReliSortOptions] = useState<{ sortBy: { id: string; desc: boolean | undefined }[] }>({
    sortBy: [],
  });
  const [reliPagination, setReliPagination] = useState<{ page: number; rowsPerPage: number } | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setToastifySettings(empty_toast_settings);
    }, 100);
  }, [toastifySettings.msg]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (baseUrl) {
      getSession(baseUrl);
    }
  }, [baseUrl]);

  useEffect(() => {
    if (user.success && maxGatewaySessionExpirationHours.success) {
      setGatewaySessionCookies(maxGatewaySessionExpirationHours.value);
    }
  }, [user, maxGatewaySessionExpirationHours]);

  useEffect(() => {
    if (isLoading && apps.success && role.success && posts.success) {
      setIsLoading(false);
    }
  }, [isLoading, apps, role, posts]);

  const onUserSetUp = async () => {
    const roleRes = await GatewayManagementAPI.getCurrentRole();

    if (roleRes.success && roleRes.role) {
      const currentRole = roleRes.role;
      setRole({ ...currentRole, success: true });
      const resGatewaySetup = await GatewayManagementAPI.getGlobalCosmicConfigPublic();

      if (resGatewaySetup.success) {
        if (resGatewaySetup.data?.needs_setup) {
          alert('Instance needs setup');
          Utils.navigateToLogin();
        }
      }
      refresh();

      navigateToRoute(currentRole);
    } else {
      alert('An error occurred while retrieving the role data.');
      Utils.navigateToLogin();
    }
  };

  const getDocumentLibraryType = async () => {
    const res = await GatewayManagementAPI.getGlobalCosmicConfigPublic();
    if (res?.success && res.data && res.data.document_library) {
      const documentLibraryType = res.data.document_library;
      setDocumentLibrary(documentLibraryType);
    } else {
      setDocumentLibrary(IDocumentLibraryType.Sharefile);
    }
  };

  const getInfo = async () => {
    const userRes = await GatewayManagementAPI.getUser();
    if (userRes.success && userRes.user) {
      setUser({ ...userRes.user, success: true });
    }

    const roleRes = await GatewayManagementAPI.getCurrentRole();
    if (roleRes.success && roleRes.role) {
      setRole({ ...roleRes.role, success: true });

      if (roleRes.role.is_admin === true) {
        const _requestTemplatesRes = await GatewayManagementAPI.getRequestTemplates();
        if (_requestTemplatesRes?.requestTemplates) {
          setRequestTemplates(_requestTemplatesRes?.requestTemplates);
        }
      }

      getAllPosts();

      const sessionStatus = await GatewayManagementAPI.getSessionStatus();
      const sessionData = sessionStatus.data?.session;

      sessionStatus.success && Object.keys(sessionData?.roleShadowMode || {}).length
        ? setShadowedRole({
            isShadowed: true,
            shadowedRole: {
              label: sessionData?.roleShadowMode?.label || '',
              id: sessionData?.roleShadowMode?.id || '',
            },
            assignedRole: {
              label: sessionData?.role?.label || '',
              id: sessionData?.role?.id || '',
              isAdmin: sessionData?.role?.is_admin || false,
            },
          })
        : setShadowedRole({
            isShadowed: false,
            shadowedRole: {
              label: '',
              id: '',
            },
            assignedRole: {
              label: sessionData?.role?.label || '',
              id: sessionData?.role?.id || '',
              isAdmin: sessionData?.role?.is_admin || false,
            },
          });

      sessionStatus.success && sessionData?.sessionShadowMode?.shadowUserEmail
        ? setShadowedUser({
            isShadowed: true,
            shadowedUserEmail: sessionData.sessionShadowMode.shadowUserEmail,
          })
        : setShadowedUser({
            isShadowed: false,
            shadowedUserEmail: '',
          });

      const linkCategoriesRes = await CommonLinksAPI.getCommonLinks(LinkCategoriesTypeEnum.dashboardSidebar);

      if (linkCategoriesRes.success && Array.isArray(linkCategoriesRes.data)) {
        setLinkCategories({ success: true, linkCategoriesArray: linkCategoriesRes.data });
      }

      getSessionMaxHours();
      getBaseURL();
      getDocumentLibraryType();
    }

    const arrayRequest: any[] = [GatewayManagementAPI.getCurrentApps(), GatewayManagementAPI.getTeams()];

    if (hasFinancialPlanning() && !!userRes.user?.id && window.location.origin.includes('tan.miradorgateway.com')) {
      arrayRequest.push(GatewayManagementAPI.getKeysFinancialPlanningAreasValues(userRes.user.id));
    }

    Promise.all(arrayRequest).then((values) => {
      const [appsRes, teamRes, keysPlanningAreasValueRes] = values;

      setApps({ success: true, apps: appsRes.success && Array.isArray(appsRes.apps) ? appsRes.apps : [] });

      if (teamRes.success) {
        setAdvisors({ success: true, advisors: teamRes.data || [] });
      }

      if (keysPlanningAreasValueRes?.success) {
        const { keysPlanningAreasValue } = keysPlanningAreasValueRes;
        const financialPlanningMapped: Types.IKeyAreasOfPlanning[] = areasOfPlanningTitles.map(
          (area: Types.IKeyAreasOfPlanning) => ({ ...area, value: keysPlanningAreasValue[area.key] }),
        );
        setKeysFinancialPlanning(financialPlanningMapped);
      }

      if (userRes.success && roleRes.success && appsRes.success && teamRes.success) {
        let res = {
          success: true,
          user: userRes.user,
          role: roleRes.role,
          apps: appsRes.apps,
          advisors: teamRes.data?.advisors || [],
        };
        if (hasFinancialPlanning() && keysFinancialPlanning) res = { ...res.advisors, keysFinancialPlanning };
        return res;
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    });
  };

  const getBaseURL = async () => {
    const urlRes = await GatewayManagementAPI.getBaseUrlData();
    if (urlRes.success && urlRes.url) {
      setBaseUrl(urlRes.url);
      sessionStorage.setItem('baseUrl', urlRes.url);
    }
  };

  const getSessionMaxHours = async () => {
    const sessionMaxHoursRes = await GatewayManagementAPI.getGlobalCosmicConfigPublic();

    setMaxGatewaySessionExpirationHours({
      success: true,
      value:
        sessionMaxHoursRes.success && sessionMaxHoursRes.data?.gateway_session_max_hours
          ? sessionMaxHoursRes.data.gateway_session_max_hours
          : defaultOktaSessionExpirationHours,
    });
  };

  const getSession = async (url: string, isRefreshingSession?: boolean) => {
    const status = isRefreshingSession
      ? await GatewayManagementAPI.refreshOktaSession(url)
      : await GatewayManagementAPI.getOktaSessionStatus(url);

    if (status.success && status.isActive && status.expiresAt && typeof status.expiresAt === 'string') {
      const timeValue = String(new Date(status.expiresAt).getTime());
      Cookie.setOktaSessionExpirationTime(timeValue);
      return { success: true };
    }
    return { success: false };
  };

  const setGatewaySessionCookies = (maxHours: number) => {
    const gatewaySessionTimeout =
      user.timeout_to_extend_session && user.timeout_to_extend_session <= maxHours
        ? user.timeout_to_extend_session
        : maxHours;

    const sessionExpirationTimeString = String(new Date().getTime() + gatewaySessionTimeout * HOUR_MILISEC);

    Cookie.setGatewaySessionExpiresAt(sessionExpirationTimeString);
  };

  const hasFinancialPlanning = () =>
    role?.main_layout_sorting?.findIndex((layout) => layout.title === 'keyPlanningAreas') !== -1;

  const refresh = async () => {
    setUser({ success: false });
    setPosts({ success: false });
    setRole({ success: false });
    setApps({ success: false });
    setLinkCategories({ success: false });
    setAdvisors({ success: false });
    setIsLoading(true);
    const res = await getInfo();
    return res;
  };

  const refreshRole = async (showLoading?: boolean, useCache?: boolean) => {
    if (showLoading) {
      setIsLoading(true);
    }
    const roleRes = await GatewayManagementAPI.getCurrentRole(useCache);
    setIsLoading(false);
    if (roleRes.success && roleRes.role) {
      setRole({ ...roleRes.role, success: true });
    }
  };

  const refreshUser = async (showLoading?: boolean) => {
    if (showLoading) {
      setIsLoading(true);
    }
    const userRes = await GatewayManagementAPI.getUser();
    setIsLoading(false);
    if (userRes.success && userRes.user) {
      setUser({ ...userRes.user, success: true });
    }
  };

  const activateShadowedRole = async (isActive: boolean, roleId: string) => {
    try {
      isActive
        ? await GatewayManagementAPI.getShadowedRoleData(roleId)
        : await GatewayManagementAPI.deleteShadowedRole();

      refresh();
    } catch (error) {
      setToastifySettings({
        type: MessageTypes.Error,
        msg: `Error: shadow role update failed.`,
      });

      await Utils.sleep(5000);

      refresh();
    }
  };

  const activateShadowedUser = async (isActive: boolean, userId: string) => {
    try {
      isActive
        ? await GatewayManagementAPI.getShadowedUserData(userId)
        : await GatewayManagementAPI.deleteShadowedUser();

      refresh();
    } catch (error) {
      setToastifySettings({
        type: MessageTypes.Error,
        msg: `Error: shadow user update failed.`,
      });

      await Utils.sleep(5000);

      refresh();
    }
  };

  async function getAllPosts() {
    const res = await BlogPostsAPI.getAllAvailablePosts();
    const newPosts = res.success && res.posts && Array.isArray(res.posts) ? res.posts : [];
    setPosts({
      success: true,
      posts: newPosts,
    });
  }

  const refreshPosts = async () => {
    await getAllPosts();
  };

  const extendGatewaySession = () => {
    if (user.success && maxGatewaySessionExpirationHours.success) {
      setGatewaySessionCookies(maxGatewaySessionExpirationHours.value);
    }
  };

  const extendOktaSession = async () => {
    if (baseUrl) {
      const { success } = await getSession(baseUrl, true);
      return { success };
    }
    return { success: false };
  };

  if (role && role.colors) {
    theme = createTheme(theme, {
      palette: {
        primary: {
          main: role.colors[0].color,
        },
        secondary: {
          main: role.colors[1].color,
        },
      },
      components: {
        MuiFormControl: {
          styleOverrides: {
            root: {
              '& label.Mui-focused': {
                color: role.colors[0].color,
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& label.Mui-focused': {
                color: role.colors[0].color,
              },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            list: {
              padding: 0,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: role.colors[0].color,
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: role.colors[0].color,
              },
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              height: 20,
              width: 42,
              margin: '0 10px',
              padding: 0,
              display: 'flex',
              '&:active': {
                '& .MuiSwitch-thumb': {
                  width: 20,
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(22px)',
                },
              },
              '& .MuiSwitch-switchBase': {
                padding: 0,
                '&.Mui-checked': {
                  transform: 'translateX(22px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                width: 20,
                height: 20,
                borderRadius: 12,
                transition: theme.transitions.create(['width'], {
                  duration: 200,
                }),
              },
              '& .MuiSwitch-track': {
                border: `1px solid ${role.colors[0].color}`,
                borderRadius: 12,
                opacity: 1,
                backgroundColor: 'rgba(255,255,255,.55)',
                boxSizing: 'border-box',
              },
            },
          },
        },
      },
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider
        value={{
          syncNewUsersLoading,
          syncUsersInfoLoading,
          shadowedRole,
          shadowedUser,
          user,
          role,
          apps,
          posts,
          advisors,
          recentTransactions,
          portfolioServiceName,
          portfolioData,
          toastifySettings,
          baseUrl,
          isLoading,
          requestTemplates,
          keysFinancialPlanning,
          defaultOktaSessionExpirationHours,
          maxGatewaySessionExpirationHours,
          documentLibrary,
          linkCategories,
          setSyncNewUsersLoading,
          setSyncUsersInfoLoading,
          setLinkCategories,
          setToastifySettings,
          setPosts,
          refresh,
          refreshRole,
          refreshUser,
          refreshPosts,
          onUserSetUp,
          activateShadowedRole,
          activateShadowedUser,
          setRecentTransactions,
          setPortfolioServiceName,
          setPortfolioData,
          extendGatewaySession,
          extendOktaSession,
          sortedRows,
          setSortedRows,
          reliFilter,
          setReliFilter,
          reliSortOptions,
          setReliSortOptions,
          reliPagination,
          setReliPagination,
          setRole,
        }}
      >
        {isLoading ? <FullScreenLoader /> : children}
      </UserContext.Provider>
    </ThemeProvider>
  );
};
