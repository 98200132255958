export enum clientAppTitleEnum {
  mirador = 'mirador',
  lnw = 'lnw',
}

export default {
  GATEWAY_ID: process.env.GATSBY_GATEWAY_ID,
  API_BASE_URL: process.env.GATSBY_API_BASE_URL,
  GATSBY_API_BASE_URL: process.env.GATSBY_API_BASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  TENANT_ENV: process.env.TENANT_ENV,
  GATSBY_FROALA_ACTIVATION_KEY: process.env.GATSBY_FROALA_ACTIVATION_KEY,
  GATSBY_RELI: process.env.GATSBY_RELI,
  GATSBY_RELI_FILES_BASE_URL: process.env.GATSBY_RELI_FILES_BASE_URL,
  GATSBY_GA_TRACKER_ID: process.env.GATSBY_GA_TRACKER_ID,
  GATSBY_COGNICOR_PROJECT_ID: process.env.GATSBY_COGNICOR_PROJECT_ID,
  GATSBY_HONEYBADGER_API_KEY: process.env.GATSBY_HONEYBADGER_API_KEY,
  GATSBY_HONEYBADGER_ENV: process.env.GATSBY_HONEYBADGER_ENV,
  GATSBY_OKTA_QUICK_SIGHT_LINK: process.env.GATSBY_OKTA_QUICK_SIGHT_LINK,
  GATSBY_OKTA_DOMAIN: process.env.GATSBY_OKTA_DOMAIN,
  GATSBY_OKTA_CLIENT_ID: process.env.GATSBY_OKTA_CLIENT_ID,
  GATSBY_ENVIRONMENT_DOMAIN: process.env.GATSBY_ENVIRONMENT_DOMAIN,
  GATSBY_IDP_ID: process.env.GATSBY_IDP_ID,
  IS_MOCK_SERVER_ON: process.env.IS_MOCK_SERVER_ON,
  MOCK_SERVER_API_KEY: process.env.MOCK_SERVER_API_KEY,
  MOCK_SERVER_URL: process.env.MOCK_SERVER_URL,
  GATSBY_INSTANCE_TYPE: process.env.GATSBY_INSTANCE_TYPE,
  GATSBY_CLIENT_APP_TITLE: process.env.GATSBY_CLIENT_APP_TITLE as clientAppTitleEnum,
  GATSBY_THIRD_RD_PARTY_SERVICE_SECRET_KEY: process.env.GATSBY_THIRD_RD_PARTY_SERVICE_SECRET_KEY,
  GATSBY_GATEWAY_ID: process.env.GATSBY_GATEWAY_ID,
  GATSBY_REQUEST_SECRET_KEY: process.env.GATSBY_REQUEST_SECRET_KEY,
  GATSBY_OKTA_USE_INTERACTION_CODE: process.env.GATSBY_OKTA_USE_INTERACTION_CODE,
  GATSBY_USE_STEWARD_CUSTOM_REDIRECT: process.env.GATSBY_USE_STEWARD_CUSTOM_REDIRECT,
  GATSBY_USE_REFRESH_TOKEN_HANDLER: process.env.GATSBY_USE_REFRESH_TOKEN_HANDLER,
};
