export enum MenuItemsEnum {
  default = 'navigation-item',
  reference = 'reference',
}

export type IMenuItemsIcons = {
  [key in menuItemsIconsEnum]: JSX.Element;
};

export enum menuItemsIconsEnum {
  dashboardIcon = 'dashboardIcon',
  calendarIcon = 'calendarIcon',
  requestsIcon = 'requestsIcon',
  employeeDirectoryIcon = 'employeeDirectoryIcon',
  offlineDataManagementIcon = 'offlineDataManagementIcon',
  reliIcon = 'reliIcon',
  k1Icon = 'k1Icon',
  spacesIcon = 'spacesIcon',
  privateInvestmnetDeskIcon = 'privateInvestmnetDeskIcon',
  insightsIcon = 'insightsIcon',
  documentLibraryIcon = 'documentLibraryIcon',
  toolsIcon = 'toolsIcon',
  teamDirectoryIcon = 'teamDirectoryIcon',
  myClientsIcons = 'myClientsIcons',
  addeparLairdIcon = 'addeparLairdIcon',
  settingsIcon = 'settingsIcon',
  unknownItemIcon = 'unknownItemIcon',
}

export type RoleMenuItem = {
  config: IRoleDefaultMenuItem | IRoleReferenceMenuItem;
};

export interface IMenuItemsResponse {
  status: number;
  data: { items?: (IDefaultMenuItem | IReferenceMenuItem)[] };
}

export interface IRoleDefaultMenuItem extends IDefaultMenuItemConfig {
  id: string;
  is_active: boolean;
  is_session_shadow_supported?: boolean;
}

export interface IIDefaultMenuItemSubitems {
  title: string;
  is_active: boolean;
  redirect_url: string;
  order: number;
}

export interface IDefaultMenuItemConfig {
  type: MenuItemsEnum.default;
  title: string;
  redirect_url: string;
  icon_key: menuItemsIconsEnum;
  order: number;
  sub_items: IIDefaultMenuItemSubitems[];
}
export interface IDefaultMenuItem {
  id: string;
  config: IDefaultMenuItemConfig;
}

export interface IRoleReferenceMenuItem extends IReferenceMenuItemConfig {
  id: string;
  is_active: boolean;
  is_session_shadow_supported?: boolean;
}
export interface IReferenceMenuItemConfig {
  icon_key: menuItemsIconsEnum;
  model: string;
  order: number;
  ref_slug: string;
  title: string;
  type: MenuItemsEnum.reference;
}
export interface IReferenceMenuItem {
  id: string;
  config: IReferenceMenuItemConfig;
  item: {
    id: string;
    slug: string;
    title: string;
    metadata: {
      configurations: {
        order: number;
        manage_integrations: {
          is_active: boolean;
          redirect_url: string;
        };
      };
      is_active: boolean;
      quicksights: {
        name: string;
        id: string;
      }[];
    };
  };
}

export interface IQuicksiteItem {
  id: string;
  title: string;
  slug: string;
  metadata: {
    configurations: {
      icon_key: string;
      manage_integrations: {
        is_active: boolean;
        redirect_url: string;
      };
    };
    quicksights: {
      name: string;
      id: string;
    }[];
  };
}
