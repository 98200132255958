export enum salesforceVendorsEnum {
  addepar = 'Addepar',
  blackDiamond = 'Black Diamond',
  orion = 'Orion',
}

export enum salesforceVendorsUrlEnum {
  addepar = 'addepar',
  blackDiamond = 'diamond',
  orion = 'orion',
}

export type structuredSystemFieldsArrBuilderMode = 'new' | 'edit';

export enum salesForceSystemEnum {
  Portfolio_Accounting_System__c = 'Portfolio_Accounting_System__c',
  Parent_Gateway_Instance__r = 'Parent_Gateway_Instance__r',
  Integration_Active__c = 'Integration_Active__c',
  Instance_Secret__c = 'Instance_Secret__c',
  Instance_URL__c = 'Instance_URL__c',
  Instance_Key__c = 'Instance_Key__c',
  ClientAccount = 'ClientAccount',
  Owner = 'Owner',
  Name = 'Name',
  Id = 'Id',
  Addepar_Alts_Tracking_Attribute_Name__c = 'Addepar_Alts_Tracking_Attribute_Name__c',
  Addepar_Firm_ID__c = 'Addepar_Firm_ID__c',
  Addepar_Last_Transac__c = 'Addepar_Last_Transac__c',
  Addepar_Data_Source_Attribute_Name__c = 'Addepar_Data_Source_Attribute_Name__c',
  BD_Username__c = 'BD_Username__c',
  BD_Password__c = 'BD_Password__c',
  BD_Subscription_Key__c = 'BD_Subscription_Key__c',
}

export interface IIdNameData {
  Id: string;
  Name: string;
}

export interface IPortfolioAccountingSystem {
  [salesForceSystemEnum.Id]: string;
  [salesForceSystemEnum.Integration_Active__c]: boolean | string;
  [salesForceSystemEnum.Name]: string;
  [salesForceSystemEnum.Parent_Gateway_Instance__r]: IIdNameData;
  [salesForceSystemEnum.Owner]: IIdNameData;
  [salesForceSystemEnum.ClientAccount]: IIdNameData;
  [salesForceSystemEnum.Instance_Key__c]?: string;
  [salesForceSystemEnum.Instance_Secret__c]?: string;
  [salesForceSystemEnum.Instance_URL__c]?: string;
}

export interface IPortfolioAccountingSystemOrion extends IPortfolioAccountingSystem {
  [salesForceSystemEnum.BD_Username__c]?: string;
  [salesForceSystemEnum.BD_Password__c]?: string;
  [salesForceSystemEnum.Portfolio_Accounting_System__c]: salesforceVendorsEnum.orion;
}

export interface IPortfolioAccountingSystemAddepar extends IPortfolioAccountingSystem {
  [salesForceSystemEnum.Addepar_Data_Source_Attribute_Name__c]: string;
  [salesForceSystemEnum.Addepar_Alts_Tracking_Attribute_Name__c]: string;
  [salesForceSystemEnum.Addepar_Last_Transac__c]: string;
  [salesForceSystemEnum.Portfolio_Accounting_System__c]: salesforceVendorsEnum.addepar;
  [salesForceSystemEnum.Addepar_Firm_ID__c]?: string;
}

export interface IPortfolioAccountingSystemBlackDiamond extends IPortfolioAccountingSystem {
  [salesForceSystemEnum.Portfolio_Accounting_System__c]: salesforceVendorsEnum.blackDiamond;
  [salesForceSystemEnum.BD_Username__c]?: string;
  [salesForceSystemEnum.BD_Password__c]?: string;
  [salesForceSystemEnum.BD_Subscription_Key__c]?: string;
}

export type PortfolioAccountingSystemCombinedType =
  | IPortfolioAccountingSystemAddepar
  | IPortfolioAccountingSystemBlackDiamond
  | IPortfolioAccountingSystemOrion;

export type PortfolioAccountingSystemCombinedArrayType = PortfolioAccountingSystemCombinedType[];

export interface IStateProps {
  parentGatewayInstances: IIdNameData[];
  system: PortfolioAccountingSystemCombinedType;
  salesforceSystems: PortfolioAccountingSystemCombinedArrayType;
  clientAccs: IIdNameData[];
  portfolio_accounting_system: salesforceVendorsEnum;
}

export interface ISalesForceSystem {
  state: Partial<IStateProps>;
  slug: string;
}

export interface IOneSystemTable {
  vendor: salesforceVendorsEnum;
  system: PortfolioAccountingSystemCombinedType;
  parentGatewayInstances: IIdNameData[];
  isCreatingNewSystem: boolean;
  allClientAccounts: IIdNameData[];
  setSalesforceSystem: React.Dispatch<React.SetStateAction<PortfolioAccountingSystemCombinedType | undefined>>;
}

export interface IAllSystemsTable {
  systems: PortfolioAccountingSystemCombinedArrayType;
  parentGatewayInstances: IIdNameData[];
  allClientAccounts: IIdNameData[];
}

export interface ISystemDeleteModalProps {
  isOpened: boolean;
  title: string;
  closeModal: () => void;
  onDelete: () => void;
}

export type ISystemRefreshModalProps = Omit<ISystemDeleteModalProps, 'title' | 'onDelete'> & {
  salesforceSystems: PortfolioAccountingSystemCombinedArrayType;
  systemId?: string;
};

export interface SystemInstructionsModalProps extends Omit<ISystemDeleteModalProps, 'title' | 'onDelete'> {
  vendor: salesforceVendorsEnum;
}

export interface IDeleteBtnProps {
  isCreatingNewSystem: boolean;
  openDeleteModal: () => void;
}

export interface ISaveSystemPayload {
  Portfolio_Accounting_System__c: string;
  Parent_Gateway_Instance__c: string;
  Integration_Active__c: boolean;
  Name: string;
  ClientAccount__c: string;
  options: {
    Instance_Secret__c?: string;
    Instance_URL__c?: string;
    Instance_Key__c?: string;
    Addepar_Data_Source_Attribute_Name__c?: string;
    Addepar_Last_Transac__c?: string;
    Addepar_Alts_Tracking_Attribute_Name__c?: string;
    Addepar_Firm_ID__c?: string;
    BD_Username__c?: string;
    BD_Password__c?: string;
    BD_Subscription_Key__c?: string;
  };
}

export interface IColumnsArr {
  title: string;
  key: keyof PortfolioAccountingSystemCombinedType;
}

export const userFriendlyKeyTitles = {
  Name: 'Integration Name',
  ClientAccount: 'Client Name',
  Owner: 'Owner',
  Integration_Active__c: 'Is Active',
  Portfolio_Accounting_System__c: 'Portfolio System',
  Id: 'Salesforce Record ID',
  Instance_URL__c: 'Instance URL',
  Instance_Key__c: 'API Key',
  Instance_Secret__c: 'API Secret',
  Parent_Gateway_Instance__r: 'Parent gateway instance',
  key: 'Key',
  Addepar_Alts_Tracking_Attribute_Name__c: 'Addepar Alts Tracking ID Field Name',
  Addepar_Last_Transac__c: 'Addepar Last Transaction Field Name',
  Addepar_Firm_ID__c: 'Instance ID',
  Addepar_Data_Source_Attribute_Name__c: 'Addepar Data Source Attribute Name',
  BD_Password__c: 'Password',
  BD_Subscription_Key__c: 'Black Diamond Subscription Key',
  BD_Username__c: 'Username',
};
