import axios from 'axios';

import { Cookie } from '..';
import {
  IIdNameData,
  ISaveSystemPayload,
  IPortfolioAccountingSystemBlackDiamond,
  IPortfolioAccountingSystemAddepar,
  PortfolioAccountingSystemCombinedArrayType,
  salesforceVendorsEnum,
  IPortfolioAccountingSystemOrion,
} from '../../constants/SalesforceConstants';
import { getFailedApiCallResponse } from '../utils';

export const getPortfolioAccountingSystems = async (
  type: salesforceVendorsEnum,
): Promise<{
  success: boolean;
  data?: PortfolioAccountingSystemCombinedArrayType;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get(`/gateway/salesforce/portfolio-accounting-systems?type=${type}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      let systemData: PortfolioAccountingSystemCombinedArrayType = [];

      switch (type) {
        case salesforceVendorsEnum.addepar:
          systemData = res.data.data as IPortfolioAccountingSystemAddepar[];
          break;
        case salesforceVendorsEnum.blackDiamond:
          systemData = res.data.data as IPortfolioAccountingSystemBlackDiamond[];
          break;
        case salesforceVendorsEnum.orion:
          systemData = res.data.data as IPortfolioAccountingSystemOrion[];
          break;
        default:
          break;
      }

      return {
        success: true,
        data: systemData,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getParentGatewayInstances = async (): Promise<{
  success: boolean;
  data?: IIdNameData[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/salesforce/gateways', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getBlackDiamondInstances = async (): Promise<{
  success: boolean;
  data?: IIdNameData[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/black-diamond', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const createNewSystem = async (
  data: ISaveSystemPayload,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post('/gateway/salesforce/portfolio-accounting-systems', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 201) {
      return {
        success: true,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const editSystem = async (
  id: string,
  system: ISaveSystemPayload,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.put(`/gateway/salesforce/portfolio-accounting-systems/${id}`, system, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteSystem = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.delete(`/gateway/salesforce/portfolio-accounting-systems/${id}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const clearCache = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get(`/gateway/salesforce/portfolio-accounting-systems/${id}/clear-cache`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

const getSalesforceUrl = (vendor: salesforceVendorsEnum, id: string) => {
  let url = '';

  switch (vendor) {
    case salesforceVendorsEnum.addepar:
      url = `/gateway/addepar/sync/${id}`;
      break;
    case salesforceVendorsEnum.blackDiamond:
      url = `/gateway/black-diamond/sync/${id}`;
      break;
    case salesforceVendorsEnum.orion:
      url = `/gateway/orion/sync/${id}`;
      break;
    default:
      break;
  }

  return url;
};

export const getSystemStatus = async (
  id: string,
  vendor: salesforceVendorsEnum,
): Promise<{
  success: boolean;
  status?: string;
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  const url = getSalesforceUrl(vendor, id);

  if (!url) {
    return { success: false, error: 'Incorrect vendor error' };
  }

  try {
    const res = await axios.get(url, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        status: res.data.status,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const refreshSystemStatus = async (
  id: string,
  vendor: salesforceVendorsEnum,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const url = getSalesforceUrl(vendor, id);

  if (!url) {
    return { success: false, error: 'Incorrect vendor error' };
  }

  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.post(
      url,
      {},
      {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      },
    );
    if (res.status === 200) {
      return {
        success: true,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getAllClientAccs = async (): Promise<{
  success: boolean;
  data?: IIdNameData[];
  error?: string;
}> => {
  const accessToken = Cookie.getAccessToken();
  try {
    const res = await axios.get('/gateway/salesforce/client-accounts', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
