import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { uid } from 'uid';
import Honeybadger from '@honeybadger-io/js';

// libs
import Cookie from './Cookie';
import Config from '../config';
import * as Utils from '../utils';

// APIs
import * as SettingAPI from './Settings';
import * as CalendarAPI from './Calendar';
import * as EnrollmentAPI from './Enrollment';
import * as CommonLinksAPI from './CommonLinks';
import * as AuthenticationAPI from './Authentication';
import * as GatewayManagementAPI from './GatewayManagement';
import * as BoxAPI from './Box';
import * as BlogPostsAPI from './BlogPosts';
import * as ThirdPartyIntegrationsAPI from './ThirdPartyIntegrations';
import * as SalesForcePortfolioAPI from './SalesforcePortfolio';
import * as GatewayUsersAPI from './UsersAdministration';
import * as RecoveryQuestionAPI from './RecoveryQuestionManagement';
import * as QuickSightAPI from './QuickSight';
import * as AdvisoryTeamsAPI from './AdvisoryTeams';
import * as MfaAPI from './MFA';

axios.defaults.baseURL = Config.API_BASE_URL;
axios.interceptors.request.use((config) => {
  config.headers['gateway-request-id'] = uid(32);
  Honeybadger.setContext({
    request_id: config.headers['gateway-request-id'],
  });
  return config;
});

interface RejectedResponse {
  message?: string;
}

axios.interceptors.response.use(
  (fulfilledResponse: AxiosResponse) => fulfilledResponse,
  async (rejectedResponse: AxiosError<RejectedResponse>) => {
    const TOKEN_EXPIRE_ERROR_MESSAGE = 'jwt expired';

    const isTokenExpire = rejectedResponse.response?.data?.message === TOKEN_EXPIRE_ERROR_MESSAGE;
    if (!isTokenExpire) {
      return Promise.reject(rejectedResponse);
    }

    const failedAxiosCall: AxiosRequestConfig = { ...rejectedResponse.config };
    if (!failedAxiosCall.headers) {
      return Promise.reject(rejectedResponse);
    }

    const refreshSessionResponse = await AuthenticationAPI.refreshSession();

    if (!refreshSessionResponse.success) {
      return Utils.navigateToLogin();
    }

    try {
      const refreshedAccessToken = Cookie.getAccessToken();

      Object.assign(failedAxiosCall.headers, { 'x-access-token': refreshedAccessToken });

      return axios(failedAxiosCall);
    } catch (error) {
      console.error('Request retry failed:', error);
      return Promise.reject(error);
    }
  },
);

export {
  Cookie,
  EnrollmentAPI,
  AuthenticationAPI,
  GatewayManagementAPI,
  SettingAPI,
  CommonLinksAPI,
  CalendarAPI,
  BoxAPI,
  BlogPostsAPI,
  ThirdPartyIntegrationsAPI,
  SalesForcePortfolioAPI,
  GatewayUsersAPI,
  RecoveryQuestionAPI,
  QuickSightAPI,
  AdvisoryTeamsAPI,
  MfaAPI,
};
