import axios from 'axios';
import _ from 'lodash';

import { Cookie } from '..';
import { getFailedApiCallResponse } from '../utils';

export const getQuickSightEmbedUrl = async (
  dashboardId: string,
): Promise<{
  success: boolean;
  embedUrl?: string;
  isFirstTimeProvisioning?: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`gateway/quik-sight/dashboard-embed-url?dashboardId=${dashboardId}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, embedUrl: res.data.data.EmbedUrl || '' };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const regex = new RegExp(/User .* does not exist\./);
      const isFirstTimeProvisioningMessage = regex.test(e.response?.data?.message || '');

      const errorResponse = e.response?.data?.status || e.response?.data?.message;
      const errorMessage = errorResponse ? errorResponse : e;
      return {
        success: false,
        error: String(errorMessage),
        isFirstTimeProvisioning: isFirstTimeProvisioningMessage,
      };
    }
    return { success: false, error: String(e) };
  }
  return { success: false };
};
